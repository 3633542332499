import React from "react";
import { Link } from "gatsby";
import ConnectorIcon from "./connector-icon";
import { SignupButton } from "../buttons/frontegg";

const Metric = ({
  title,
  image,
  one_liner,
  slug,
  connectors = [],
  tags = [],
  fullOneLinerHeight = false,
  showConnection = true,
  category,
  ...props
}) => {
  const icons = connectors.map((tag, index) => (
    <div key={index} className="inline-block min-w-[32px]">
      <ConnectorIcon name={tag} />
    </div>
  ));

  return (
    <Link to={slug} className="block group">
      <div className=" border border-[#F3F2EF] rounded-lg bg-white library-metric hover:border-[#AFBACA] ">
        <div className="md:pt-[13px] px-[13px] rounded-t-lg bg-[#F0F3F9] hidden md:block">
          <img
            src={image?.url}
            alt={`${image?.alt}`}
            className="w-full rounded-t print:visible"
            style={{
              boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.05)",
            }}
          />
        </div>

        <div className="p-4 ">
          <div className="space-y-2.5 ">
            <div className="space-y-2 min-h-[98px]">
              <h3 className="font-medium leadigng-[24px] text-[#2D3643] library-metric-title  overflow-hidden h-[48px] ">
                {title}
              </h3>

              <p
                className={
                  "text-[#727C8D] text-xs leading-[20px] " +
                  (fullOneLinerHeight ? "" : "lines2")
                }
                title={one_liner}
              >
                {one_liner}
              </p>
            </div>
            {showConnection && (
              <div className="space-y-2.5">
                <div className="space-x-[1px]">{icons}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

const MetricPreview = ({
  _id,
  title,
  image,
  one_liner,
  slug,
  connectors = [],
  tags = [],
  fullOneLinerHeight = false,
  showConnection = true,
  category,
  openPopupWith,
  ...props
}) => {
  const parts = _id.split("||");
  const integration = parts[0];
  const metric = parts[1];

  const icons = connectors.map((tag, index) => (
    <div key={index} className="inline-block min-w-[32px]">
      <ConnectorIcon name={tag} />
    </div>
  ));

  return (
    <div className="block group">
      <div className=" border border-[#C9CDD3] rounded-lg bg-white library-metric hover:border-[#AFBACA] ">
        <div className="p-4 ">
          <div>
            <span className="bg-[#EDFBF7] px-2 py-[2px] text-xs text-[#16A077] font-medium leading-[20px] rounded-[2px]">
              Beta
            </span>
          </div>
          <div className=" mt-2">
            <h3 className="font-medium text-[#3D434C] leading-[24px]   overflow-hidden h-[48px] ">
              {title}
            </h3>
          </div>

          <div className="flex flex-row justify-between">
            <div>
              {showConnection && (
                <div className="space-y-2.5">
                  <div className="space-x-[1px]">{icons}</div>
                </div>
              )}
            </div>
            <div>
              <button
                onClick={() => openPopupWith(integration, metric)}
                className="border border-[#D7DFE9] rounded-[4px] py-1 px-3 text-xs text-[#2D3643] fomt-medium leading-[20px] metric_cta click_use_this_metric "
              >
                {"Request"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



const BuildYourOwn = ({
  title,
  one_liner,
  
  tags = [],
  fullOneLinerHeight = false,
  showConnection = true,
  category,
  integration,
  ...props
}) => {
  
  return (
    <div className="block group">
      <div className=" border border-[#E9EFF6] rounded-lg bg-white library-metric hover:border-[#AFBACA] ">
        <div className="md:pt-5 px-8 rounded-t-lg bg-[#F0F3F9] hidden md:block">
          
          <div className="h-[160px] bg-white px-4 py-5 text-[28px] font-medium leadin-[26.6px] tracking-[-0.04em] ">
              <div className="max-w-[165px]">Build your own!</div>
          </div>
        </div>

        <div className="p-4 ">
          <div className="space-y-2.5 ">
            <div className="space-y-2 min-h-[98px]">
              <h3 className="font-medium text-[#28293D] library-metric-title  overflow-hidden h-[48px] ">
                <span className="hidden md:inline-block">{title}</span>
                <span className="md:hidden">{'Build your own!'}</span>
              </h3>

              <p
                className={
                  "text-[#5E718D] text-xs leading-[20px] " +
                  (fullOneLinerHeight ? "" : "lines2")
                }
                title={one_liner}
              >
                {one_liner}
              </p>
            </div>
            
              <div className="space-y-2.5">
                <div className="space-x-[1px]">
                  {/* <button className="text-[#1B4DFF] font-medium min-h-[38px] inline-block">Start Now --> </button> */}
                  
                  <SignupButton className="text-[#1B4DFF] font-medium min-h-[38px] inline-block" 
                  type="custom_metric"
                  integration={integration}
                  name=""
                  >
                  Start Now --> 
                  </SignupButton>
                </div>
              </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};


export default Metric;
export { MetricPreview, BuildYourOwn };
