import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
//import { Navigation, Pagination } from 'swiper/modules';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
//import EqContainer from "./contianer";

//import { register } from 'swiper/element/bundle';
// register Swiper custom elements
//register();

const MetricLobbySlider = ({
  items,
  slidesPerView = 3,
  spaceBetween = 16,
  nextEl = ".metrics-swiper-button-next",
  prevEl = ".metrics-swiper-button-prev",
  loop = false,
}) => {
  return (
    <div
      className="w-full  "
      //id={"template-metrics-" + {index+1}}
    >
      <Swiper
        modules={[Navigation /*,  Pagination*/]}
        //className="  lg:w-3/5 "
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        loop={loop}
        //onSlideChange={() => console.log('slide change')}
        //onSwiper={(swiper) => console.log(swiper)}
        //navigation={true}
        // pagination={{
        //   clickable: true
        // }}
        navigation={{
          nextEl: nextEl,
          prevEl: prevEl,
        }}
      >
        {/* <div class="swiper-pagination absolute right-[20px] top-[-8px]"></div> */}
        {items.map((item, i) => (
          <SwiperSlide className="" key={i}>
            {item}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export const MetricLobbySliderMain = ({
  items,
  slidesPerView = 3,
  spaceBetween = 16,
  nextEl = ".metrics-swiper-button-next",
  prevEl = ".metrics-swiper-button-prev",
  loop = false,
  slidesPerGroup = 1,
}) => {
  return (
    <div
      className="  "
      //id={"template-metrics-" + {index+1}}
    >
      <Swiper
        modules={[Navigation, Pagination]}
        className="swiper-container"
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        slidesPerGroup={slidesPerGroup}
        loop={loop}
        //onSlideChange={() => console.log('slide change')}
        //onSwiper={(swiper) => console.log(swiper)}
        //navigation={true}
        pagination={{
          //el: '.swiper-custom-pagination',
          clickable: true,
        }}
        navigation={{
          nextEl: nextEl,
          prevEl: prevEl,
        }}
      >
        {items.map((item, i) => (
          <SwiperSlide className="" key={i}>
            {item}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export const CarouselSlideShow = ({
  items,
  className = 'swiper-container-carousel',
  slidesPerView = 3,
  spaceBetween = 16,
  nextEl = ".metrics-swiper-button-next",
  prevEl = ".metrics-swiper-button-prev",
  loop = false,
  slidesPerGroup = 1,
  paginationTargetId = "",
}) => {
  useEffect(() => {
    const e = document.getElementById('id-' + paginationTargetId )
    const newParent = document.getElementById(paginationTargetId);
    newParent.appendChild(e); // Move to the end of newParent

  }, [paginationTargetId])

  return (

    <div className="w-full overflow-hidden">
      <Swiper
        modules={[Navigation, Pagination]}
        className={className}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        slidesPerGroup={slidesPerGroup}
        loop={loop}
        //onSlideChange={() => console.log('slide change')}
        //onSwiper={(swiper) => console.log(swiper)}
        //navigation={true}
        pagination={{
          el: '.swiper-' + paginationTargetId,
          clickable: true,
        }}
        navigation={{
          nextEl: nextEl,
          prevEl: prevEl,
        }}
      >
        <div className={'swiper-' + paginationTargetId} id={'id-'+paginationTargetId}></div>

        {items.map((item, i) => (
          <SwiperSlide className="" key={i}>
            {item}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>

  );
};

export default MetricLobbySlider;
