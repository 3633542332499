import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/Seo";
import Metric from "../components/marketplace/metric";
import Arrow from "../images/chevron-right-large.svg";
import playBtn from "../images/playBtn.svg";
//import Play1 from "../images/Play1.svg";
import YouTube from "react-youtube";
import { BrowserView, MobileView } from "react-device-detect";
import { CarouselSlideShow } from "../components/marketplace/metric-lobby-slider";
import useMarketplace from "../hooks/useMarketplace";
import {
  Container,
  Card2 as Card,
  SideBlock,
  BackButton,
} from "../components/marketplace/elements";
import Remark from "../components/remark";
import fileIcon from "../images/file-02.svg";
import tagIcon from "../images/tag-01.svg";
import {
  //ArticlesOfMetrics,
  HowItWorksBlock,
  WhatIsItBlock,
} from "../components/marketplace/blocks";
//import TryOut from "../components/buttons/tryout";
import {
  SignupButton,
  TemplateSignupDropdownButton,
} from "../components/buttons/frontegg";
import SocialToolbar from "../components/social-toolbar";
import getMetatags from "../util/getMetatags";
//import { useLocation } from "@reach/router";

//const isProduction = `${process.env.GATSBY_ACTIVE_ENV}` === "production";

function MarketplaceTemplatePage({
  data: { node, extra },
  path,
  location,
  ...props
}) {
  const {
    _id,
    title,
    bussiness_questions,
    description,
    one_liner,
    metrics,
    category,
    workflows,
    connectors,
    persona,
    //slug,
    youtube_id,
    youtube_description,
    //meta_title,
    //meta_description,
    integrations_templates2,
  } = node;

  //console.log( extra)
  //console.log( 'integrations_templates2', integrations_templates2)

  //integrations_templates2 = integrations_templates2.map()

  const cats = [...category, ...workflows, ...persona];

  const seo = prepareSeo(node, extra);

  const videoId = youtube_id ? youtube_id : false;

  const { urls } = useMarketplace();

  return (
    <Layout showCta={false} isMarketplace={true}>
      <Seo {...seo} />
      <div className="h-[70px]"></div>
      <div className=" max-w-screen-xl	mx-auto px-6 lg:px-[80px] ">
        <Container className="flex flex-col lg:flex-row justify-between ">
          <Container.Main className="lg:w-[680px]">
            <BackButton
              title="<-- Back to Templates Library"
              url={urls.library + "?tab=templates"}
              className="hidden md:block print:hidden print:md:hidden"
            />
            <Card
              className=" pb-9 flex flex-row justify-start gap-x-5 items-top mt-5 md:mt-0 "
              title={title}
              description={one_liner}
              category={category}
            />

            <SocialToolbar className="flex flex-row justify-end items-center gap-x-[22px] pb-4  border-b  " />

            {/* <div className="border-t"></div> */}

            <div className="space-y-12 pt-12 ">
              <Questions
                items={bussiness_questions}
                title="Business questions"
                subtitle="This template addresses the following:"
              />

              <div className="border-t"></div>

              <MetricsCarusel
                items={metrics}
                id={`template-metrics`}
                title={`Included Metrics (${metrics.length})`}
              />

              <div className="border-t"></div>

              <SectionBody
                description={description}
                title={title + ` template description`}
              />

              <VideoSection
                videoId={videoId}
                title={youtube_description ?? "Learn more about this Template "}
              />

              <div className="border-t"></div>

              <Tags title={"Tags:"} items={cats} className=" print:hidden" />

              <div className="border-t   print:hidden"></div>

              <HowItWorksBlock className="py-[30px] space-y-6 break-before-page" />
            </div>
          </Container.Main>
          <Container.Side className=" lg:w-[360px] hidden md:block  print:hidden print:md:hidden">
            <SideBlock
              title={title}
              description={one_liner}
              className=" border border-[#D7DFE9] rounded-lg p-6 hidden md:block lg:sticky top-[156px]  print:hidden "
            >
              <UseOrRequestButtons
                // data={integrations_templates2.map((t) => ({
                //   ...t,
                //   template_title: title,
                // }))}
                data={integrations_templates2}
                title={title}
                template={_id}
              />

              {/* <TryOut
                title="Explore Demo Account"
                className="mt-6 w-full text-base font-medium pt-2.5 pb-[13px] px-5 rounded-lg leading-[20px] whitespace-nowrap dark:hidden bg-transparent hover:bg-[#F2F5FF] text-[#1B4DFF] border border-[#4A72FF] demo_account "
                check={true}
              /> */}

              <div className="bg-[#D7DFE9] h-[1px] w-full my-6"></div>

              <div className=" space-y-8">
                <SideBlock.MetricsSummary metrics={metrics} />

                <SideBlock.Connectors
                  connectors={connectors.map((e) => e.name)}
                />
              </div>
            </SideBlock>
          </Container.Side>
        </Container>
      </div>

      <div className=" max-w-screen-xl px-6 lg:px-[80px] mx-auto space-y-16 lg:space-y-[142px] my-16 print:hidden ">
        <WhatIsItBlock />
      </div>
    </Layout>
  );
}

const UseOrRequestButtons = ({ data, title, template }) => {
  //console.log( data )
  //console.log( title, template )
  //const location = useLocation();
  //const template = id

  //console.log( title )
  //console.log( referral_page_name )
  //if( data.length === 0 ) return false;

  if (data.length === 0) {
    return (
      <SignupButton
        //key={index}
        className={
          "bg-[#1B4DFF] w-full  text-white py-2.5 rounded-lg text-base font-medium border-0 template_cta click_use_this_template hover:bg-[#0F3CD9]"
        }
        name={template}
        type="template"
        //integration={integration}
        intercom_message={`Hi there 👋 I'm interested in the ${title} template. Could you provide more details? Thanks!`}
      >
        {title}
      </SignupButton>
    );
  }

  // if (data.length === 1) {
  //   const d = data[0];
  //   //return (

  //   <SignupButton
  //     className="bg-[#1B4DFF] w-full  text-white py-2.5 rounded-lg text-base font-medium border-0 template_cta click_use_this_template hover:bg-[#0F3CD9]"
  //     type={"template"}
  //     name={d.template}
  //   >
  //     {d.is_ready ? "Use this Template" : "Request this template"}
  //   </SignupButton>;
  //   //)
  // }

  return (
    <TemplateSignupDropdownButton
      //template_name={title}
      title={"Start with These Metrics"}
      //template={template}
      template_id={template}
      template_title={title}
      integrations={data}
      className={
        "bg-[#1B4DFF] w-full  text-white py-2.5 rounded-lg text-base font-medium border-0 template_cta click_use_this_template hover:bg-[#0F3CD9]"
      }
    />
  );
};

const SectionBody = ({ title, description, children }) => {
  return (
    <div className="space-y-6 break-before-page">
      <div className="flex flex-row justify-start items-center gap-3">
        <img src={fileIcon} alt="" className="inline" />
        <h2 className="text-xl font-medium leading-[140%] text-[#2D3643]">
          {title}
        </h2>
      </div>
      <div>
        <div className="block print:hidden">
          <ShowMore title="Show more">
            <div className="marketplace-body ">
              <Remark>{description}</Remark>
            </div>
          </ShowMore>
        </div>

        <div className="hidden print:block">
          <div className="marketplace-body ">
            <Remark>{description}</Remark>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

const Questions = ({ title, subtitle, items }) => {
  return (
    <Questions.Container className="space-y-7 ">
      <div className="space-y-2">
        <Questions.Title
          title={title}
          className="text-[20px] leading-[140%] text-[#1C222B] font-medium"
        />
        <Questions.SubTitle
          title={subtitle}
          className="text-sm leading-[20p[x]] text-[#5E718D] font-normal"
        />
      </div>
      <Questions.Body>
        {items.map((q, i) => (
          <Questions.Card
            key={i}
            text={q}
            className="text-base  leading-[24px] tracking-[-0.16px] font-medium flex flex-row justify-start items-top gap-x-5 w-full text-[#2D3643] "
          />
        ))}
      </Questions.Body>
    </Questions.Container>
  );
};

Questions.Container = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

Questions.Title = ({ title, className }) => {
  return <h2 className={className}>{title}</h2>;
};

Questions.SubTitle = ({ title, className }) => {
  return <div className={className}>{title}</div>;
};

Questions.Body = ({ children }) => {
  return (
    <div className="text-[#1C222B]">
      <ul className="space-y-5 ">{children}</ul>
    </div>
  );
};

Questions.Card = ({ text, className }) => {
  return (
    <li className={className}>
      <div className="min-w-[24px]">
        <img src="/help-circle.svg" alt="circle" className="block w-full" />
      </div>
      <div className="grow">{text}</div>
    </li>
  );
};

const Tags = ({ title, items, className }) => {
  //console.log(items)
  const cards = items.map((item, index) => (
    <div
      key={index}
      className="border text-xs bg-white border-[#E9EFF6] rounded-[2px] py-[2px] px-2"
    >
      {item.name}
    </div>
  ));

  return (
    <div className={className + " space-y-2 text-sm  text-[#5E718D] "}>
      <div className="flex flex-row gap-3 justify-start items-start">
        <img src={tagIcon} alt="" className="" />

        <div key="special" className="text-[#8897AE] font-medium">
          {title}
        </div>
        <div className="flex flex-row flex-wrap justify-start gap-2.5 items-center">
          {cards}
        </div>
      </div>
    </div>
  );
};

const MetricsCarusel = ({ items, id, title, uri = "/", uri2 = "/" }) => {
  const [numberOfSlides, setNumberOfSlides] = useState(3);

  useEffect(() => {
    const doResize = () => {
      const viewportWidth = window.innerWidth;

      if (viewportWidth < 768) {
        setNumberOfSlides(1);
      } else if (viewportWidth >= 768 && viewportWidth < 1024) {
        setNumberOfSlides(2);
      } else if (viewportWidth >= 1024 && viewportWidth < 1280) {
        setNumberOfSlides(3);
      } else if (viewportWidth >= 1280 && viewportWidth < 1536) {
        setNumberOfSlides(3);
      } else if (viewportWidth >= 1536 && viewportWidth < 1700) {
        setNumberOfSlides(3);
      } else {
        setNumberOfSlides(3);
      }
    };
    window.addEventListener("resize", doResize);
    doResize();
  }, [setNumberOfSlides]);

  useEffect(() => {
    const doResize = () => {
      const childElement = document.querySelector("#card-all-" + id);
      if (childElement) {
        const parentHeight =
          childElement?.offsetParent?.offsetParent?.offsetHeight;
        childElement.style.height = parentHeight + "px";
      }
    };

    window.addEventListener("resize", doResize);
    setTimeout(doResize, 500);
  }, [id]);

  // const link = {
  //   label: `Show all (${items.length})`,
  //   uri: uri2,
  // };

  const slides = items.map((item, index) => (
    <div className="" key={index}>
      <Metric {...item} />
    </div>
  ));

  //slides.push(<MetricsCarusel.LinkToAllCard uri={uri} id={"card-all-" + id} />);

  const isMobile = false;

  return (
    <Carousel.Container className="space-y-4">
      <Carousel.Header className=" flex flex-row justify-between items-center space-y-4 lg:space-y-0">
        <Carousel.Title
          title={title}
          className="text-[20px] font-medium text-[#2D3643] leading-[140%] text-center lg:text-left"
        />

        <div className="flex flex-col lg:flex-row gap-x-4 gap-y-4  items-center">
          <div>
            <div id={`pagination-${id}`} className="pagination-slot"></div>
          </div>
        </div>
      </Carousel.Header>

      <Carousel.Body className="relative carousel-body">
        <Carousel.ButtonPrev id={id} />
        <Carousel.ButtonNext id={id} />

        <CarouselSlideShow
          items={slides}
          slidesPerView={isMobile ? 1 : numberOfSlides}
          //spaceBetween={isMobile ? 8 : 8}
          className="swiper-container-carousel-in-template-page"
          nextEl={".templates-swiper-button-next-" + id}
          prevEl={".templates-swiper-button-prev-" + id}
          slidesPerGroup={isMobile ? 1 : numberOfSlides}
          paginationTargetId={`pagination-${id}`}
        />
      </Carousel.Body>
    </Carousel.Container>
  );
};

const Carousel = ({ className, children }) => {
  return (
    <Carousel.Container className={className}>
      <Carousel.Header>
        <Carousel.Title>title</Carousel.Title>
      </Carousel.Header>
      <Carousel.Body>{children}</Carousel.Body>
    </Carousel.Container>
  );
};

Carousel.Container = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

Carousel.Header = ({ children, title, link, className }) => {
  return <div className={className}>{children}</div>;
};

Carousel.Title = ({ title, className }) => {
  return (
    <div className={className}>
      <h2>{title}</h2>
    </div>
  );
};

Carousel.Body = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

Carousel.ButtonPrev = ({ id, position }) => {
  return (
    <div className="group absolute left-[-60px] w-[46px] top-0 bottom-0 z-[2] hidden lg:flex flex-col items-center justify-around">
      <div className={"hidden lg:block lg:w-8 z-[4]"}>
        <button className={" w-8 templates-swiper-button-prev-" + id}>
          <img src={Arrow} alt="arrow" className="rotate-180 inline-block " />
        </button>
      </div>

      <div className="absolute top-0 bottom-0 left-0 right-0 bg-white opacity-70 z-[3]" />
    </div>
  );
};

Carousel.ButtonNext = ({ id, position }) => {
  return (
    <div className="group absolute right-[-60px]  w-[46px] top-0 bottom-0 z-[2] hidden lg:flex flex-col items-center justify-around ">
      <div className={"hidden lg:block lg:w-8 z-[4] "}>
        <button className={" w-8 templates-swiper-button-next-" + id}>
          <img src={Arrow} alt="arrow" className="inline-block" />
        </button>
      </div>
      <div className="absolute top-0 bottom-0 left-0 right-0 bg-white opacity-70 z-[3]" />
    </div>
  );
};

const VideoSection = ({ videoId, title }) => {
  const [openVideo, setOpenVideo] = useState(false);
  const [videoPlayer, setVideoPlayer] = useState(null);

  const onReady = (player) => {
    setVideoPlayer(player);
  };

  const showVideo = () => {
    //console.log("open");
    setOpenVideo(true);
    videoPlayer.playVideo();
  };

  if (!videoId) return;

  return (
    <div className="pb-8 space-y-2 print:hidden">
      <div className="marketplace-body p-0">{title}</div>

      <button className={!openVideo ? "block" : "hidden"} onClick={showVideo}>
        <div className="relative">
          <img
            //src={`https://img.youtube.com/vi/${videoId}/0.jpg`}
            src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
            //src={`https://i.ytimg.com/vi_webp/${videoId}/maxresdefault.webp`}
            //src={`https://i.ytimg.com/vi_webp/${videoId}/mqdefault.webp`}
            className=" rounded-lg inline-block lg:w-[700px]"
            alt="video thumb"
            // width="420px"
          />

          <div className="absolute  top-0 left-0	 w-full h-full flex justify-center items-center">
            <img
              src={playBtn}
              style={{ fill: "rgba(255, 255, 255, 0.50)" }}
              className="inline-block"
              alt="video play button"
            />
          </div>
        </div>
      </button>

      <div className={openVideo ? "block  " : "hidden"}>
        <BrowserView>
          <Video video_id={videoId} onReady={onReady} width="700px" />
        </BrowserView>
        <MobileView>
          <Video video_id={videoId} onReady={onReady} width="100%" />
        </MobileView>
      </div>
    </div>
  );
};

const Video = ({ video_id, onReady, className = "", width = "" }) => {
  const opts = {
    // height: "100%",
    width: width, // "100%",
    // height: "472",
    // width: "840",
    /*height: "450",*/
    /*width: "800",*/
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      loop: 1,
      rel: 0,
    },
  };

  return (
    <YouTube
      videoId={video_id}
      opts={opts}
      className={className + " "}
      /*style={{width: '100% !important'}}*/
      onReady={(event) => onReady(event.target)}
    />
  );
};

const ShowMore = ({ children, title }) => {
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
  };
  return (
    <div /*onClick={handleClick}*/>
      <div className={show ? "" : "h-[250px] overflow-hidden relative"}>
        {children}
        {!show && (
          <div
            className="absolute inset-0"
            style={{
              background: "linear-gradient(transparent 100px, white)",
            }}
          ></div>
        )}
      </div>
      {!show && (
        <button
          onClick={handleClick}
          className="font-medium leadin-[20px] text-center w-full"
        >
          <span className="underline">{title}</span>
          {/* {" >"} */}
        </button>
      )}
    </div>
  );
};

const prepareSeo = (node, extra) => {
  let seo;
  if (extra) {
    seo = getMetatags(
      extra.metatag,
      extra?.relationships?.OGImage?.localFile?.publicURL
        ? extra?.relationships?.OGImage?.localFile?.publicURL
        : "/Marketplace_-_OG-3.png"
    );
  } else {
    seo = {
      title: node.meta_title
        ? node.meta_title
        : `Template - ${node.title} | Metrics Library | Sightfull`,
      description: node.meta_description
        ? node.meta_description
        : node.one_liner,
      meta: [
        {
          name: "image",
          property: `og:image`,
          content: "/Marketplace_-_OG-3.png",
        },
        {
          property: `og:image:alt`,
          content: "template",
        },
        {
          name: "title",
          content: `Template - ${node.title}`,
        },
      ],
    };
  }

  return seo;
};

export default MarketplaceTemplatePage;

export const query = graphql`
  query mCollection2($id: String!, $slug: String!) {
    node: marketplaceTemplate(id: { eq: $id }) {
      id
      title
      slug
      _id
      __typename
      one_liner
      bussiness_questions
      description
      integrations_templates2: integrations_templates {
        __typename
        integration
        template
        is_ready
      }
      category {
        id
        name
      }
      connectors {
        id
        name
      }
      persona {
        id
        name
      }
      workflows {
        id
        name
      }
      metrics {
        __typename
        id
        _id
        title
        slug
        one_liner
        link_to_glossary
        category {
          name
        }
        image {
          id
          height
          url
          alt
        }
      }
      youtube_id
      youtube_description
      meta_title
      meta_description
    }
    extra: nodeCollection(
      path: { alias: { eq: $slug } }
      status: { eq: true }
    ) {
      metatag {
        attributes {
          content
          name
        }
        tag
      }
    }
  }
`;
